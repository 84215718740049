import * as React from 'react';
import { useEffect, useRef } from 'react';

export interface LogsProps {
    logs: React.ReactNode[];
    className: React.ComponentProps<'div'>['className'];
}

export const ScrollList = ({ logs, className }: LogsProps) => {
    const scrollContainerRef = useRef(null);
    const isUserScrollingUp = useRef(false);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        const handleScroll = () => {
            // Check if the user has scrolled up
            if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) {
            isUserScrollingUp.current = false;
            } else {
            isUserScrollingUp.current = true;
            }
        };

        scrollContainer.addEventListener('scroll', handleScroll);

        return () => {
            scrollContainer.removeEventListener('scroll', handleScroll);
        };
    });

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;

        const scrollToBottom = () => {
          if (!isUserScrollingUp.current) {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
          }
        };

        scrollToBottom();
    }, [logs]);

    return (
        <div className={className} ref={scrollContainerRef}>
            {logs.map((log, index) => <div key={index} className={'log'}>{log}</div>)}
        </div>
    );
};
